import React from 'react'
import { Link } from 'gatsby'
import Head from '../components-en/head'
import Layout from '../components-en/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import takvim from "../images/takvim.png"
import takvimPdf from "../../static/2022-Hedefleri-Takvimi.pdf"

const Calendar = () => {
    return (
        <Layout>
            <Head title="Your calendar is ready!" />
            <div className="row calendar">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Your calendar is ready! <span role="img" aria-label="emoji">🐳</span></h1>
                <p>Download now, write your goals and plans. Don't forget to 
                    start a circle with your friends for your goals. 
                </p>
                <h5>
                    <a className="button"  rel="noreferrer" target="_blank" href={takvimPdf} >Download now</a>
                </h5>
                <h5><Link to="/" >Return to homepage</Link></h5>
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={takvim} alt="Takvim"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default Calendar